import axios from "axios";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { startOfDay, endOfDay, format, addHours } from "date-fns";
let baseUrl = process.env.VUE_APP_NOTI_WEBAPI_URL;

export const Notification = {
    BookingSubmit: async (booking_id) => {
        //let response = await axios.post(baseUrl + "ProdigyFlyNotification/BookingSubmit", { Title: title, SubTitle: subTitle, Body: body, ImgUrl: imgUrl });
        var queryBooking = {}
        queryBooking.b_id = booking_id
        queryBooking.$eager = "[company,bookingpassenger.[user],airport,bookingleg.airport, customer]"
        let resdataBooking = await feathersClientUOA
            .service("booking")
            .find({ query: queryBooking });

        let databooking = resdataBooking.data[0]
        console.log(databooking)
        var leg = databooking.airport.ap_name + "=>"
        for (let index = 0; index < databooking.bookingleg.length; index++) {
            leg += databooking.bookingleg[index].airport.ap_name + "=>"
        }
        leg += databooking.airport.ap_name

        var q = {};
        q.message = `
Submit Booking From ${databooking.customer.o_name}
Flight No. : ${databooking.f_no}
Customer : ${databooking.customer.o_name}
Departure Time : ${format(addHours(new Date(databooking.b_departure_date), -7), "dd-MM-yyyy HH:mm")}
Leg : ${leg}
Passenger In : ${databooking.bookingpassenger.filter(x => x.pt_id == 2).length}
Passenger Out : ${databooking.bookingpassenger.filter(x => x.pt_id == 1).length}`


        q.chanel = databooking.airport.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    BookingAccept: async (booking_id) => {
        //let response = await axios.post(baseUrl + "ProdigyFlyNotification/BookingAccept", { Title: title, SubTitle: subTitle, Body: body, ImgUrl: imgUrl });
        var queryBooking = {}
        queryBooking.b_id = booking_id
        queryBooking.$eager = "[company,bookingpassenger.[user],airport,bookingleg.airport, customer]"
        let resdataBooking = await feathersClientUOA
            .service("booking")
            .find({ query: queryBooking });

        let databooking = resdataBooking.data[0]
        var leg = databooking.airport.ap_name + "=>"
        for (let index = 0; index < databooking.bookingleg.length; index++) {
            leg += databooking.bookingleg[index].airport.ap_name + "=>"
        }
        leg += databooking.airport.ap_name

        var q = {};
        q.message = `
Accept Booking From ${databooking.customer.o_name}
Flight No. : ${databooking.f_no}
Customer : ${databooking.customer.o_name}
Departure Time :  ${format(addHours(new Date(databooking.b_departure_date), -7), "dd-MM-yyyy HH:mm")}
Leg : ${leg}
Passenger In : ${databooking.bookingpassenger.filter(x => x.pt_id == 2).length}
Passenger Out : ${databooking.bookingpassenger.filter(x => x.pt_id == 1).length}`


        q.chanel = databooking.airport.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    BookingRevise: async (booking_id) => {
        var queryBooking = {}
        queryBooking.b_id = booking_id
        queryBooking.$eager = "[company,bookingpassenger.[user],airport,bookingleg.airport, customer]"
        let resdataBooking = await feathersClientUOA
            .service("booking")
            .find({ query: queryBooking });

        let databooking = resdataBooking.data[0]
        console.log(databooking)
        var leg = databooking.airport.ap_name + "=>"
        for (let index = 0; index < databooking.bookingleg.length; index++) {
            leg += databooking.bookingleg[index].airport.ap_name + "=>"
        }
        leg += databooking.airport.ap_name

        var q = {};
        q.message = `Revise Booking From : ${databooking.customer.o_name}
Revise No : ${databooking.b_revise_count} (${format(addHours(new Date(databooking.b_revise_last_update), -7), "dd-MM-yyyy HH:mm")})
Flight No. : ${databooking.f_no}
Customer : ${databooking.customer.o_name}
Departure Time : ${format(addHours(new Date(databooking.b_departure_date), -7), "dd-MM-yyyy HH:mm")}
Leg : ${leg}
Passenger In : ${databooking.bookingpassenger.filter(x => x.pt_id == 2).length}
Passenger Out : ${databooking.bookingpassenger.filter(x => x.pt_id == 1).length}`


        q.chanel = databooking.airport.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    FlightCreateFromBooking: async (flight_id) => {
        var queryFlight = {};
        queryFlight.f_id = flight_id;
        console.log(flight_id)
        queryFlight.$eager = "[airports,terminal,flightstatus,aircrafts,aircraftsrevise,arrivalairport,departairport,leg,leg.airport,passenger,cargo.airport, customer, pilot, copilot, flightType]"
        let resdataflight = await feathersClientUOA
            .service("flight")
            .find({ query: queryFlight });

        let dataflight = resdataflight.data[0]
        console.log(dataflight)
        var leg = dataflight.airports.ap_name + "=>"
        console.log(118)
        for (let index = 0; index < dataflight.leg.length; index++) {
            leg += dataflight.leg[index].ap_name + "=>"
        }
        console.log(122)
        leg += dataflight.airports.ap_name

        var q = {};
        q.message = `
 New Flight From Booking
 Flight No. : ${dataflight.f_flight_no}
 Customer : ${dataflight.customer.o_name}
 Departure Time :${format(addHours(new Date(dataflight.ap_depart_date), -7), "dd-MM-yyyy HH:mm")}
 Leg : ${leg}
 Passenger In : ${dataflight.passenger.filter(x => x.pt_id == 2).length}
 Passenger Out : ${dataflight.passenger.filter(x => x.pt_id == 1).length}`


        q.chanel = dataflight.airports.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    FlightCreate: async (flight_id) => {
        var queryFlight = {};
        queryFlight.f_id = flight_id;
        console.log(flight_id)
        queryFlight.$eager = "[airports,terminal,flightstatus,aircrafts,aircraftsrevise,arrivalairport,departairport,leg,leg.airport,passenger,cargo.airport, customer, pilot, copilot, flightType]"
        let resdataflight = await feathersClientUOA
            .service("flight")
            .find({ query: queryFlight });

        let dataflight = resdataflight.data[0]

        var q = {};
        q.message = `
 New Flight ${dataflight.customer.o_name}
 Flight No. : ${dataflight.f_flight_no}
 Departure Time : ${format(addHours(new Date(dataflight.ap_depart_date), -7), "dd-MM-yyyy HH:mm")}`


        q.chanel = dataflight.airports.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    FlightReprotApprove: async (title, subTitle, body, imgUrl) => {
        let response = await axios.post(baseUrl + "ProdigyFlyNotification/FlightReprotApprove", { Title: title, SubTitle: subTitle, Body: body, ImgUrl: imgUrl });
        return response;
    },
    FlightReportSentToSAP: async (title, subTitle, body, imgUrl) => {
        let response = await axios.post(baseUrl + "ProdigyFlyNotification/FlightReportSentToSAP", { Title: title, SubTitle: subTitle, Body: body, ImgUrl: imgUrl });
        return response;
    },
    //Add 2020/02/15
    FlightAcceptPilot: async (flight_id) => {
        var queryFlight = {};
        queryFlight.f_id = flight_id;
        console.log(flight_id)
        queryFlight.$eager = "[airports,terminal,flightstatus,aircrafts,aircraftsrevise,arrivalairport,departairport,leg,leg.airport,passenger,cargo.airport, customer, pilot, copilot, flightType]"
        let resdataflight = await feathersClientUOA
            .service("flight")
            .find({ query: queryFlight });

        let dataflight = resdataflight.data[0]
        console.log(dataflight)
        var leg = dataflight.airports.ap_name + "=>"
        console.log(118)
        for (let index = 0; index < dataflight.leg.length; index++) {
            leg += dataflight.leg[index].ap_name + "=>"
        }
        console.log(122)
        leg += dataflight.airports.ap_name

        var q = {};
        q.message = `
 Scheduler select pilot
 Flight No. : ${dataflight.f_flight_no}
 Customer : ${dataflight.customer.o_name}
 Departure Time : ${format(addHours(new Date(dataflight.ap_depart_date), -7), "dd-MM-yyyy HH:mm")}}
 Leg :${leg}
 Pilot : ${dataflight.pilot.pl_name} 
 Copilot : ${dataflight.copilot.pl_name} 
 Accept by : ${dataflight.f_accept_pilot_by}
 `


        q.chanel = dataflight.airports.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    FlightAcceptAircraft: async (flight_id) => {
        var queryFlight = {};
        queryFlight.f_id = flight_id;
        console.log(flight_id)
        queryFlight.$eager = "[airports,terminal,flightstatus,aircrafts,aircraftsrevise,arrivalairport,departairport,leg,leg.airport,passenger,cargo.airport, customer, pilot, copilot, flightType]"
        let resdataflight = await feathersClientUOA
            .service("flight")
            .find({ query: queryFlight });

        let dataflight = resdataflight.data[0]
        console.log(dataflight)
        var leg = dataflight.airports.ap_name + "=>"
        console.log(118)
        for (let index = 0; index < dataflight.leg.length; index++) {
            leg += dataflight.leg[index].ap_name + "=>"
        }
        console.log(122)
        leg += dataflight.airports.ap_name

        var q = {};
        q.message = `
 Engineer select aircraft
 Flight No. : ${dataflight.f_flight_no}
 Customer : ${dataflight.customer.o_name}
 Departure Time : ${format(addHours(new Date(dataflight.ap_depart_date), -7), "dd-MM-yyyy HH:mm")}
 Leg :${leg}
 Aircraft : ${dataflight.aircrafts.ac_name} 
 Accept by : ${dataflight.f_accept_aircraft_by}
 `


        q.chanel = dataflight.airports.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    //Add 2020/02/16
    FlightAcceptTransport: async (transport_id) => {
        var queryTransport = {};
        queryTransport.ts_id = transport_id;
        console.log(transport_id)
        queryTransport.$eager = "[booking.[airport],transportjob,customer]"
        let resdataTransport = await feathersClientUOA
            .service("transport")
            .find({ query: queryTransport });

        let dataTransport = resdataTransport.data[0]
        console.log(dataTransport)
        const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
        }
        var passenger_in = dataTransport.transportjob.filter(x => x.pt_id == 1)
        var driver_in = [...new Set(passenger_in.map(x => x.d_name + "(" + x.v_license_plate + " )"))]
        var passenger_out = dataTransport.transportjob.filter(x => x.pt_id == 2)
        var driver_out = [...new Set(passenger_out.map(x => x.d_name + "(" + x.v_license_plate + " )"))]
        var q = {};
        q.message = `
 Transport select vehicle and driver
 Transport No. : ${dataTransport.ts_id}
 Customer : ${dataTransport.customer[0].o_name}
 Start Time :${format(addHours(new Date(dataTransport.ts_date), -7), "dd-MM-yyyy HH:mm")}
 Outbound : ${passenger_out.length} 
 Inbound : ${passenger_in.length}
 Driver Outbound : \n -${driver_out.join("\n -")}
 Driver Inbound : \n -${driver_in.join("\n -")}
 Accept by : ${dataTransport.ts_accept_transport_by}
 `
        console.log(283)

        q.chanel = dataTransport.booking.airport.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    FlightClosedCheckin: async (flight_id) => {
        var queryFlight = {};
        queryFlight.f_id = flight_id;
        console.log(flight_id)
        queryFlight.$eager = "[airports,terminal,flightstatus,aircrafts,aircraftsrevise,arrivalairport,departairport,leg,leg.airport,passenger,passenger.baggage,cargo.airport, customer, pilot, copilot, flightType]"
        let resdataflight = await feathersClientUOA
            .service("flight")
            .find({ query: queryFlight });

        let dataflight = resdataflight.data[0]
        console.log(dataflight)
        var leg = dataflight.airports.ap_name + "=>"
        console.log(303)
        for (let index = 0; index < dataflight.leg.length; index++) {
            leg += dataflight.leg[index].ap_name + "=>"
        }
        console.log(307)
        leg += dataflight.airports.ap_name
        var passenger_in = dataflight.passenger.filter(x => x.pt_id == 2 && x.ol_id == null)
        var passenger_out = dataflight.passenger.filter(x => x.pt_id == 1 && x.ol_id == null)
        var Body_Weight_arr = passenger_out.map(item => item.p_body_weight)
        var Body_Weight = 0
        if (Body_Weight_arr.length != 0) {
            Body_Weight = Body_Weight_arr.reduce((prev, next) => prev + next)
        }
        var Baggage_Weight_arr = passenger_out.map(item => item.p_baggage_weight)
        var Baggage_Weight = 0
        if (Baggage_Weight_arr.length != 0) {
            Baggage_Weight = Baggage_Weight_arr.reduce((prev, next) => prev + next)
        }
        var cargo_Weight_arr = dataflight.cargo.map(item => item.cg_weight)
        var cargo_Weight = 0
        if (cargo_Weight_arr.length != 0) {
            cargo_Weight = cargo_Weight_arr.reduce((prev, next) => prev + next)
        }
        var countbaggage = 0 
        passenger_out.forEach(element => {
            countbaggage = countbaggage + element.baggage.length
        });
        var Total_Weight = Body_Weight + Baggage_Weight + cargo_Weight
        var warningtext = ''
        if(cargo_Weight+Baggage_Weight >= 300)
        {warningtext = "** warning **"}
        var q = {};
        q.message = `
 Checkin Close Flight
 Flight No. : ${dataflight.f_flight_no}
 Customer : ${dataflight.customer.o_name}
 Departure Time : ${format(addHours(new Date(dataflight.ap_depart_date), -7), "dd-MM-yyyy HH:mm")}
 Leg : ${leg}
 Passenger In : ${passenger_in.length}
 Passenger Out : ${passenger_out.length}
 Body Weight :  ${Body_Weight} kg
 Baggage Weight : ${Baggage_Weight} kg (${countbaggage})
 Cargo Weight: ${cargo_Weight} kg
 --------------------
 Baggage And Cargo Weight: ${cargo_Weight+Baggage_Weight} kg  ${warningtext}
 --------------------
 Total Weight : ${Total_Weight} kg
 `
        q.chanel = dataflight.airports.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
    FlightOffUpdateAvailablePayload: async (flight_id) => {
        var queryFlight = {};
        queryFlight.f_id = flight_id;
        queryFlight.$eager = "[airports,terminal,flightstatus,aircrafts,aircraftsrevise,arrivalairport,departairport,leg,leg.airport,passenger,cargo.airport, customer, pilot, copilot, flightType]"
        let resdataflight = await feathersClientUOA
            .service("flight")
            .find({ query: queryFlight });

        let dataflight = resdataflight.data[0]
        var leg = dataflight.airports.ap_name + "=>"
        for (let index = 0; index < dataflight.leg.length; index++) {
            leg += dataflight.leg[index].ap_name + "=>"
        }
        leg += dataflight.airports.ap_name
        let aircraft = "";
        if(dataflight.aircrafts != null){
            aircraft = dataflight.aircrafts.ac_name;
        }else{
            aircraft = "-";
        }
        var q = {};
        q.message = `
 Available Payload : ${dataflight.f_available_payload} kg.
 Flight No. : ${dataflight.f_flight_no}
 Customer : ${dataflight.customer.o_name}
 Departure Time : ${format(addHours(new Date(dataflight.ap_depart_date), -7), "dd-MM-yyyy HH:mm")}
 Leg : ${leg}
 Aircraft : ${aircraft}
 `
        q.chanel = dataflight.airports.ap_name
        var response = await feathersClientUOA
            .service("linenotification")
            .find({ query: q });
        return response;
    },
}