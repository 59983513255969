import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import vuetify from './plugins/vuetify';
import loading from './plugins/loading-screen';
import synfusion from './plugins/synfusion';

import { CurrentUser } from '../src/utils/CurrentUser'
import { CheckWarning } from '../src/utils/CheckWarning'
import { ProdigyFlyService } from '../src/utils/ProdigyFlyService'
import { LogoService} from '../src/utils/LogoService'
import { LogoLegService} from '../src/utils/LogoLegService'
import { ImagesService} from '../src/utils/ImagesService'
import { Monthlyreport} from '../src/utils/MonthlyReport'
import { FuelReport } from '../src/utils/FuelReport'
import { Notification } from '../src/utils/Notification'
import { LocalStorageService } from './utils/LocalStoragService'

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import HighchartsVue from 'highcharts-vue'

Vue.use(HighchartsVue)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.config.productionTip = false

Vue.prototype.$CurrentUser = CurrentUser;
Vue.prototype.$CheckWarning = CheckWarning;
Vue.prototype.$ProdigyFlyService = ProdigyFlyService;
Vue.prototype.$LogoService = LogoService;
Vue.prototype.$LogoLegService = LogoLegService;
Vue.prototype.$ImagesService = ImagesService;
Vue.prototype.$MonthlyReport = Monthlyreport;
Vue.prototype.$FuelReport = FuelReport;
Vue.prototype.$Notification = Notification;
Vue.prototype.$LocalStorageService = LocalStorageService;

new Vue({
  router,
  store,
  vuetify,
  loading,
  render: h => h(App)
}).$mount('#app')
