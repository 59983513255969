import feathersClientUOA from "../plugins/feathers-client-uoa";
import { LogoService } from "./LogoService";
export const FuelReport = {
    getPdf: async (month, year, oid, monthname, liststation) => {
        var datarow = []
        var dataheader =[]
        var datafooter =[]
        var index =0;
        var fuelstation=[
            "VTSH TANK",
            "PTTEP TANK 1",
            "PTTEP TANK 2",
            "RECOVERY",
            "BQP",
            "AQP",
            "QPS",
            "MDLQ",
            "DD4",
            "CKP",
            " ",
        ]
        for(var i=0;i<parseInt(liststation.length/3);i=i+1)
        {   console.log("/")
            try {
                var resultdata = await FuelReport.getdata(month, year, oid,liststation[index],liststation[index+1],liststation[index+2])
                console.log("Service")
                console.log(resultdata)
                var headerpagearr  = [fuelstation[liststation[index]-1],fuelstation[liststation[index+1]-1],fuelstation[liststation[index+2]-1]]
                dataheader.push(headerpagearr)
                datarow.push(resultdata[0]);
                datafooter.push(resultdata[1]);
                console.log("Service devide")
                console.log(datarow)
            }
            catch (error) {
                console.log(error)
            }
            index=index+3
    
        }
        if(liststation.length%3 >0)
        {   console.log("%")
            var stationindexarray = [9999,9999,9999]
            for(var index =0;index<liststation.length%3;index=index+1)
            {
                var b = (liststation.length-(liststation.length%3))+index;
                console.log("b"+b)
                stationindexarray[index]=liststation[b]
            }
            console.log(stationindexarray)
            try {
                var resultdata = await FuelReport.getdata(month, year, oid,stationindexarray[0],stationindexarray[1],stationindexarray[2])
                console.log("Service")
                console.log(resultdata)
                var headerpagearr1 ="";
                var headerpagearr2 ="";
                var headerpagearr3 ="";
                if(stationindexarray[0]!=9999)
                {
                    headerpagearr1 = fuelstation[stationindexarray[0]-1];
                }
                else
                {
                    headerpagearr1 = fuelstation[10];
                }
                if(stationindexarray[1]!=9999)
                {
                    headerpagearr2 = fuelstation[stationindexarray[1]-1];
                }
                else
                {
                    headerpagearr2 = fuelstation[10];
                }
                    headerpagearr3 = fuelstation[10];
                var headerpagearr  = [headerpagearr1,headerpagearr2,headerpagearr3]
                dataheader.push(headerpagearr)
                datarow.push(resultdata[0]);
                datafooter.push(resultdata[1]);
                console.log("Service mod")
                console.log(dataheader)
                console.log(datarow)
                console.log(datafooter)
            }
            catch (error) {
                console.log(error)
            }
            index=index+3
    
        }
        if(liststation.length == 0)
        {   console.log("%")
            var stationindexarray = [9999,9999,9999]
            console.log(stationindexarray)
            try {
                var resultdata = await FuelReport.getdata(month, year, oid,stationindexarray[0],stationindexarray[1],stationindexarray[2])
                console.log("Service")
                console.log(resultdata)
                var headerpagearr1 =fuelstation[10];
                var headerpagearr2 =fuelstation[10];
                var headerpagearr3 =fuelstation[10];
                var headerpagearr  = [headerpagearr1,headerpagearr2,headerpagearr3]
                dataheader.push(headerpagearr)
                datarow.push(resultdata[0]);
                datafooter.push(resultdata[1]);
                console.log("Service mod")
                console.log(dataheader)
                console.log(datarow)
                console.log(datafooter)
            }
            catch (error) {
                console.log(error)
            }
            index=index+3
    
        }
        var totalPage = Math.ceil(liststation.length/3);
        if(totalPage == 0)//3 FuelStation Per Page
        {
            FuelReport.CratePdf1Page(dataheader,datarow,datafooter,oid,monthname,year)
        }
        if(totalPage == 1)//3 FuelStation Per Page
        {
            FuelReport.CratePdf1Page(dataheader,datarow,datafooter,oid,monthname,year)
        }
        if(totalPage == 2)
        {
            
        }

        if(totalPage == 3)
        {
            
        }
        if(totalPage == 4)
        {
            
        }
       
    },
    getdata: async (month, year, oid,tank1,tank2,tank3) => {
        var fixlogo = 0;
        const q = {};
        q.month = month;
        q.year = year;
        q.customerid = oid;
        q.tank1 = tank1;
        q.tank2 = tank2;
        q.tank3 = tank3;

        try {
            var res = await feathersClientUOA
                .service("fuelreport")
                .find({ query: q });
            console.log(q)
            console.log("asda");
            console.log(res);
        }
        catch (error) {
            console.log(error);
        }
        var datamain = [];
        var datarow = [];
        var totaltank1 = 0;
        var totaltank2 = 0;
        var totalrecovery = 0;
        var totalfuel = 0;
        var dayinmonth = FuelReport.DaysInMonth(month, year);
        var monthMMM = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        var selectedMonthName = monthMMM[month - 1];
        var i = 0;
        var day = 1;
        while (true) {
            datarow = [];
            var date =
                day +
                " " +
                selectedMonthName +
                " " +
                year.toString().substring(4, 2);
            var Aircraft = "N/A";
            var tank1 = "-";
            var tank2 = "-";
            var recovery = "-";
            var totalfuelrow = 0;
            var remark = "No Flight Operate";
            if (i < res.length) {
                if (day < res[i].day1) {
                    Aircraft = "N/A";
                    tank1 = "-";
                    tank2 = "-";
                    recovery = "-";
                    totalfuelrow = 0;
                    remark = "No Flight Operate";
                    day = day + 1;
                } else {
                    Aircraft = res[i].ac_name;
                    tank1 = res[i].tank1;
                    tank2 = res[i].tank2;
                    recovery = res[i].tank3;
                    totalfuelrow =
                        res[i].tank1 + res[i].tank2 + res[i].tank3;
                    remark = "-";
                    totaltank1 = totaltank1 + res[i].tank1;
                    totaltank2 = totaltank2 + res[i].tank2;
                    totalrecovery = totalrecovery + res[i].tank3;
                    totalfuel = totalfuel + totalfuelrow;
                    i = i + 1;
                    if (i < res.length) {
                        if (day < res[i].day1) {
                            day = day + 1;
                        }
                    } else {
                        day = day + 1;
                    }
                }
            } else {
                date =
                    day +
                    " " +
                    selectedMonthName +
                    " " +
                    year.toString().substring(4, 2);
                Aircraft = "N/A";
                tank1 = "-";
                tank2 = "-";
                recovery = "-";
                totalfuelrow = 0;
                remark = "No Flight Operate";
                day = day + 1;
            }
            datarow = [
                {
                    text: date,
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                },
                {
                    text: Aircraft,
                    style: "tabledata",
                    alignment: "center",
                    bold: "True",
                    border: [true, false, true, true],
                },
                {
                    text: FuelReport.formatvalue(tank1),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                },
                {
                    text: FuelReport.formatvalue(tank2),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                },
                {
                    text: FuelReport.formatvalue(recovery),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                },
                {
                    text: FuelReport.formatvalue(totalfuelrow),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                },
                {
                    text: remark,
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                },
            ];
            datamain.push(datarow);
            if (day >= dayinmonth + 1) {
                break;
            }
        }
        var datatotal=[  totaltank1 ,
             totaltank2,
             totalrecovery ,
             totalfuel ,]
        return  [datamain, datatotal];
    },
    DaysInMonth: (month, year) => {
        return new Date(year, month, 0).getDate();
    },
    formatvalue(n) {
        if (n > 0) {
            return (Math.round(n * 100) / 100).toLocaleString();
        }
        else {
            return "-";
        }
    },
    CratePdf1Page:async(dataheader,datarow,datafooter,oid,monthname,year)=>{
        var uoaimage = await LogoService.GetLogoBaseurl64(1);
        var customerlogo = await LogoService.GetLogoBaseurl64(oid);
        var pdfMake = require("../../public/js/pdfmake");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("../../public/js/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var tablewidth = [50, 40, 80, 80, 80, 80, 100];
      var dd = {
        content: [
          {
            style: "tablehearder",
            table: {
              widths: [100, 310, 100],
              body: [
                [
                  {
                    image: uoaimage.logo,
                    fit: [30, 30],
                    margin: [0, 0, 0, 0],
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                  {
                    text:
                      "\nUOA-CPOC Monthly Report in " +
                      monthname +
                      ", " +
                      year,
                    margin: [0, 0, 0, 0],
                    alignment: "center",
                    border: [true, true, true, true],
                  },
                  {
                    image: customerlogo.logo,
                    fit: [30, 30],
                    margin: [0, 0, 0, 0],
                    alignment: "center",
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: null,
                    border: [false, false, false, true],
                  },
                  {
                    text: null,
                    border: [false, false, false, true],
                  },
                  {
                    text: null,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
          },
          {
            style: "newtable",
            table: {
              widths: tablewidth,
              body: [
                [
                  {
                    text: "\nDate",
                    style: "tabledata",
                    alignment: "center",
                    rowSpan: 2,
                    border: [true, true, true, true],
                  },
                  {
                    text: "\nAircarft",
                    style: "tabledata",
                    alignment: "center",
                    rowSpan: 2,
                    border: [true, true, true, true],
                  },
                  {
                    text: "\nFuel (Liters)",
                    style: "tabledata",
                    alignment: "center",
                    colSpan: 4,
                    border: [true, true, true, false],
                  },
                  {
                    text: "Flight Time",
                    style: "tabledata",
                    alignment: "center",
                    colSpan: 2,
                  },
                  {
                    text: "02:19",
                    style: "tabledata",
                    alignment: "center",
                  },
                  {
                    text: "Passenger",
                    style: "tabledata",
                    alignment: "center",
                    colSpan: 2,
                  },
                  {
                    text: "remark ",
                    style: "tabledata",
                    alignment: "center",
                    rowSpan: 2,
                  },
                ],
                [
                  {
                    text: "Date",
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, false],
                  },
                  {
                    text: "A/C",
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, false],
                  },
                  {
                    text: dataheader[0][0],
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, true],
                  },
                  {
                    text: dataheader[0][1],
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, true],
                  },
                  {
                    text: dataheader[0][2],
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, true],
                  },
                  {
                    text: "Total",
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, true],
                  },
                  {
                    text: "Remark",
                    style: "tabledata",
                    alignment: "center",
                    border: [true, true, true, false],
                  },
                ],
              ],
            },
          },
          {
            style: "table0",
            table: {
              widths: tablewidth,
              body: datarow[0],
            },
          },
          {
            style: "table0",
            table: {
              widths: tablewidth,
              body: [
                [
                  {
                    text: " ",
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                  {
                    text: "Total",
                    style: "tabledata",
                    alignment: "center",
                    bold: "True",
                    border: [true, false, true, true],
                  },
                  {
                    text: FuelReport.formatvalue(datafooter[0][0]),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                  {
                    text: FuelReport.formatvalue(datafooter[0][1]),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                  {
                    text: FuelReport.formatvalue(datafooter[0][2]),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                  {
                    text: FuelReport.formatvalue(datafooter[0][3]),
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                  {
                    text: "",
                    style: "tabledata",
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                ],
              ],
            },
          },
          "\n",
          {
            text: "CPOC Representstive: ..............................",
            style: "textfooter",
          },
          {
            text:
              "UOA Flight Operations Manager :  ..............................",
            style: "textfooter",
          },
          {
            text:
              "UOA Commercial Representative:    ..............................",
            style: "textfooter",
          },
          {
            text: "Issued by: K.Wannarat Oninthan",
            style: "textfooter",
          },
          {
            text: "Issued date:  " + monthname + " " + year,
            style: "textfooter",
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: "right",
            margin: [0, 190, 0, 80],
          },
          subheader: {
            fontSize: 14,
          },
          superMargin: {
            margin: [20, 0, 40, 0],
            fontSize: 15,
          },
          newtable: {
            margin: [-30, 10, 0, 0],
          },
          table0: {
            margin: [-30, 0, 0, 0],
            fontSize: 8,
          },
          tabledata: {
            fontSize: 10,
          },
          tabledataremark: { fontSize: 8 },

          tablehearder: { margin: [-10, -20, 0, 0] },
          textfooter: {
            margin: [225, 0, 0, 5],
            fontSize: 10,
          },
        },
      };
      pdfMake.createPdf(dd).open({});
    }




}